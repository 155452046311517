import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscribe, unsubscribe } from "../../utils/subscribe";
import { Button, Input, Card, Icon } from "semantic-ui-react";
import { COUNTRIES, COUNTRY_OPTIONS } from "../../utils/countries";
import history from "../../history";
import { setToken } from "../../actions/auth";

import { Dropdown } from "semantic-ui-react";

import Footer from "../../components/Footer";
// import Navbar from "../../components/Navbar";
import LatencySignal from "../../components/LatencySignal";
import styles from "./index.module.scss";

const queryString = require("query-string");

const Audience = () => {
  const dispatch = useDispatch();
  const defaultStreamId = queryString.parse(history.location.search).streamid;
  const defaultToken = queryString.parse(history.location.search).name;

  const [streamId, setStreamId] = useState(defaultStreamId);
  const [selectedCountry, setSelectedCountry] = useState([23.5, 121]); // 預設 Taipei
  const [isWatching, setIsWatching] = useState(false);
  const [videoStats, setVideoStats] = useState({});
  const auth = useSelector((state) => state.auth);

  const audienceVideoRef = useRef();

  useEffect(() => {
    if (!auth.token) {
      dispatch(setToken(defaultToken));
    }
  }, []);

  useEffect(() => {
    if (defaultToken && streamId && audienceVideoRef) {
      subscribe(
        streamId,
        audienceVideoRef,
        selectedCountry,
        setVideoStatsCallback
      );
      setIsWatching(() => true);
    }
  }, [auth.token]);

  const onWatch = () => {
    subscribe(
      streamId,
      audienceVideoRef,
      selectedCountry,
      setVideoStatsCallback
    );
    setIsWatching(() => true);
  };

  const setVideoStatsCallback = (stats) => {
    setVideoStats(stats);
  };

  const selectCountryHandler = (e, data) => {
    const selectedCountry = COUNTRIES[data.value].latlng;
    setSelectedCountry(() => selectedCountry);

    if (isWatching) {
      unsubscribe(audienceVideoRef);
      subscribe(
        streamId,
        audienceVideoRef,
        selectedCountry,
        setVideoStatsCallback
      );
    }
  };

  return (
    <div className={styles.audience_page}>
      <main>
        <Card fluid>
          <Card.Content>
            <h3 className={styles.card_title}>
              <Icon name="eye" />
            </h3>
          </Card.Content>
          <Card.Content style={{ padding: "0" }}>
            <video ref={audienceVideoRef} autoPlay playsInline controls />
          </Card.Content>
        </Card>
        <label>Subscribe from Region:</label>
        <Dropdown
          options={COUNTRY_OPTIONS}
          defaultValue={COUNTRY_OPTIONS[0].value}
          selection
          onChange={selectCountryHandler}
        />
        <Input
          placeholder="stream ID"
          type="text"
          value={streamId}
          style={{ margin: "0 5px" }}
          onChange={(e) => setStreamId(e.target.value)}
          disabled={defaultStreamId && defaultToken}
        />
        {isWatching ? null : (
          <Button color="teal" onClick={onWatch} disabled={!streamId}>
            Watch now
          </Button>
        )}
        {isWatching ? (
          <LatencySignal
            currentRoundTripTime={videoStats.currentRoundTripTime}
            jitterBufferDelayRate={videoStats.jitterBufferDelayRate}
          />
        ) : null}
      </main>
      <Footer />
    </div>
  );
};

export default Audience;
