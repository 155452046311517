import axios from "axios";
import history from "../../history";
import store from "../../store";
import { setSnackbar } from "../../actions/snackbar";

const baseURL = "https://streaming-admin.meshstream.io/api";

const backend = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

backend.interceptors.request.use(
  function (config) {
    /* 目前後端 api: admin 要查看特定 customer，需要先登入該 customer 帳號獲取該 customer 的 token */
    let token = "";
    if (store.getState().auth.isAdmin) {
      token = store.getState().customer.selectedCustomer.token;
    } else {
      token = store.getState().auth.token;
    }

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      // history.push('/login');
      // store.dispatch(
      //   setSnackbar({
      //     isSnackbarOpen: true,
      //     snackbarType: 'error',
      //     snackbarMessage: 'Error',
      //   })
      // );
    }
    return Promise.reject(error);
  }
);

export default {
  async GET(url, params, config) {
    try {
      const response = await backend.get(url, { params });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async POST(url, body, config) {
    try {
      const response = await backend.post(url, body, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async PUT(url, body) {
    try {
      const response = await backend.put(url, body);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
