import React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import styles from "./index.module.scss";

const Instructions = () => {
  return (
    <section className={styles.instructions}>
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column>
            <div className={styles.streamer}>
              <div className={styles.icon}>
                <Icon name="camera" color="brown" size="huge" />
              </div>
              <h3>如何開啟直播</h3>
              <h4>開啟直播 - 4步曲</h4>
              <div className={styles.step}>
                <span>(1)第一步</span>
                <span>選擇要輸出的影像解析度</span>
              </div>
              <div className={styles.step}>
                <span>(2)第二步</span>
                <span>選擇使用哪一台機器輸出</span>
              </div>
              <div className={styles.step}>
                <span>(3)第三步</span>
                <span>
                  按下 Start Broadcast
                  按鍵，等待右邊畫面出現，右邊畫面即是觀眾看到的畫面
                </span>
              </div>
              <div className={styles.step}>
                <span>(4)第四步</span>
                <span>
                  將你的 Stream ID 或 QRCode
                  告訴朋友，朋友即可從觀賞頁面觀看直播
                </span>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.viewer}>
              <div className={styles.icon}>
                <Icon name="eye" color="brown" size="huge" />
              </div>
              <h3>如何觀賞直播</h3>
              <h4>觀賞直播 - 4步曲</h4>
              <div className={styles.step}>
                <span>(1)第一步</span>
                <span> 跟朋友要求 QRCode 或 Stream ID</span>
              </div>
              <div className={styles.step}>
                <span>(2)第二步</span>
                <span>直接掃 QRCode 或是在觀看頁面輸入 Stream ID</span>
              </div>
              <div className={styles.step}>
                <span>(3)第三步</span>
                <span>按下 WATCH NOW 按鍵</span>
              </div>
              <div className={styles.step}>
                <span>(4)第四步</span>
                <span>等待 video 顯示畫面，觀賞最新串流直播</span>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  );
};

export default Instructions;
