const meshstreamClient = require("meshstream/client");
const { ConferenceApi, Utils } = meshstreamClient;

export function attachMediaStreamToVideoElement(
  conferenceApi,
  mediaStream,
  videoElement
) {
  const play = () => {
    let playPromise = videoElement.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {})
        .catch((error) => {
          videoElement.current.muted = true;
          videoElement.current.autoplay = true;
          videoElement.current.play().then(
            () => {
              console.log("errorAutoPlayCallback OK");
            },
            (error) => {
              console.log("errorAutoPlayCallback error again");
            }
          );
        });
    }
  };

  videoElement.current.srcObject = mediaStream;

  if (Utils.isSafari) {
    const onStreamChange = () => {
      videoElement.current.srcObject = new MediaStream(mediaStream.getTracks());
      play();
    };

    if (conferenceApi != null) {
      conferenceApi
        .on("addtrack", (track) => {
          onStreamChange();
        })
        .on("removetrack", (track) => {
          onStreamChange();
        });
    }
  } else if (Utils.isFirefox) {
    videoElement.current.addEventListener("pause", play);
  }
  play();
}
