import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import backend from "../../utils/backendAPI/sdkConfig";
import health_backend from "../../utils/backendAPI/health";
import randomString from "random-string";
import { subscribe } from "../../utils/subscribe";

import { COUNTRIES, COUNTRY_OPTIONS } from "../../utils/countries";
import { RESOLUTION_OPTIONS, RESOLUTIONS } from "../../utils/resolution";
import { changeExistingLocalVideoSolution } from "../../utils/changeExistingLocalVideoSolution";
import { Button, Grid, Card, Icon } from "semantic-ui-react";
import { attachMediaStreamToVideoElement } from "../../utils/attachMediaStreamToVideoElement";
import kohee1 from "../../images/kohee1.jpeg";
import kohee2 from "../../images/kohee2.jpeg";
import kohee3 from "../../images/kohee3.jpeg";

import QRCode from "qrcode.react";

import { Dropdown } from "semantic-ui-react";

import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Instructions from "../../components/Instructions";
import LatencySignal from "../../components/LatencySignal";
// import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import styles from "./index.module.scss";

const meshstreamClient = require("meshstream/client");
const { ConferenceApi, Utils } = meshstreamClient;

const streamId = randomString({ length: 6 }).toLowerCase();
const md5 = require("md5");

const Streamer = () => {
  const [selectedCountry, setSelectedCountry] = useState([23.5, 121]); // 預設 Taipei
  const [resolution, setResolution] = useState({ width: 1280, height: 720 });
  const [isPublishing, setIsPublishing] = useState(false);
  const [nodeId, setNodeId] = useState(null);
  const [stream, setStream] = useState(null);
  const [videoStatsOfStreamer, setVideoStatsOfStreamer] = useState({});
  const [videoStatsOfViewer, setVideoStatsOfViewer] = useState({});
  const { auth } = useSelector((state) => state);

  const localVideoRef = useRef();
  const audienceVideoRef = useRef();

  const getNodeId = async (url) => {
    const serviceUrl = url.split("https://")[1];
    const { nodeId } = await health_backend.POST("/nodeid", { serviceUrl });
    setNodeId(() => nodeId);
  };

  const getDisplayMediaStream = async () => {
    const mediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        width: { ideal: resolution.width },
        height: { ideal: resolution.height },
      },
      audio: true,
    });

    return mediaStream;
  };

  const getUserMediaStream = async () => {
    const mediaStream = await Utils.getUserMedia({
      video: {
        width: { ideal: resolution.width },
        height: { ideal: resolution.height },
        facingMode: "environment",
      },
      audio: true,
    });

    return mediaStream;
  };

  const startStream = async (getStreamHandler) => {
    const mediaStream = await getStreamHandler();

    const sdkConfig = await backend.POST("/customer/config/sdk", {
      operation: 1,
      stream: streamId,
      ll_from: selectedCountry,
    });

    setIsPublishing(() => true);
    subscribe(
      streamId,
      audienceVideoRef,
      selectedCountry,
      setVideoStatsCallback
    );

    if (sdkConfig) {
      const capture = new ConferenceApi({
        kinds: ["audio", "video"],
        ...sdkConfig,
      });

      setStream(() => mediaStream);
      attachMediaStreamToVideoElement(null, mediaStream, localVideoRef);
      getNodeId(sdkConfig.url);

      try {
        await capture.publish(mediaStream);
        capture.on("getstats", (result) => {
          if (result.kind == "video") {
            setVideoStatsOfStreamer(result);
          }
          if (result.kind == "audio") {
            // dispatch(setAudioStats(result));
          }
        });
      } catch (err) {
        alert(`Publish error:${err}`);
      }
    }
  };

  useEffect(() => {
    if (stream) {
      return () => {
        stream.getTracks().forEach((track) => track.stop());
        setIsPublishing(false);
      };
    }
  }, [stream]);

  const changeExistingLocalVideoSolutionHandler = ({ width, height }) => {
    if (isPublishing)
      changeExistingLocalVideoSolution(width, height, localVideoRef);
    else setResolution({ width, height });
  };

  const setVideoStatsCallback = (stats) => {
    setVideoStatsOfViewer(stats);
  };

  return (
    <div className={styles.streamer_page}>
      <Navbar />
      <Banner />
      <main>
        <Instructions />
        <Grid columns={2} stackable>
          {/* <Grid.Row> */}
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <h3 className={styles.card_title}>
                  streamer
                  <Icon name="user" />
                </h3>
              </Card.Content>
              <Card.Content style={{ padding: "0", overflow: "hidden" }}>
                <video ref={localVideoRef} autoPlay playsInline muted />
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid style={{ height: "100%" }}>
              <Card.Content>
                <h3 className={styles.card_title}>
                  viewer
                  <Icon name="eye" />
                </h3>
              </Card.Content>
              <Card.Content
                style={{ padding: "0", overflow: "hidden", height: "100%" }}
              >
                <video ref={audienceVideoRef} autoPlay playsInline muted />
              </Card.Content>
            </Card>
          </Grid.Column>
          {/* </Grid.Row> */}
        </Grid>
        <section className={styles.section_settings}>
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column>
                <Button
                  size="large"
                  color="teal"
                  onClick={() => startStream(getUserMediaStream)}
                  disabled={streamId === ""}
                >
                  Start Broadcast
                </Button>
                <Button
                  size="large"
                  color="teal"
                  onClick={() => startStream(getDisplayMediaStream)}
                  disabled={streamId === ""}
                >
                  Share Screen
                </Button>
                {isPublishing ? (
                  <span className={styles.stream_id}>
                    Stream ID: {streamId}
                  </span>
                ) : null}
                <div className={styles.dropdown_group}>
                  <div className={styles.dropdown_group_item}>
                    <label>Resolution:</label>
                    <Dropdown
                      options={RESOLUTION_OPTIONS}
                      defaultValue={RESOLUTION_OPTIONS[2].value}
                      selection
                      style={{ marginRight: "5px" }}
                      onChange={(e, data) =>
                        changeExistingLocalVideoSolutionHandler(
                          RESOLUTIONS[data.value]
                        )
                      }
                    />
                  </div>
                  <div className={styles.dropdown_group_item}>
                    <label>Publish to Region:</label>
                    <Dropdown
                      options={COUNTRY_OPTIONS}
                      defaultValue={COUNTRY_OPTIONS[0].value}
                      selection
                      disabled={isPublishing}
                      onChange={(e, data) =>
                        setSelectedCountry(() => COUNTRIES[data.value].latlng)
                      }
                    />
                    {nodeId ? (
                      <div className={styles.nodeId}>
                        currently publishing to{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://meshub-map-react.meshstream.io/dashboard-streaming?nodeid=${nodeId}`}
                        >
                          {nodeId}
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.qrcode}>
                  {isPublishing ? (
                    <React.Fragment>
                      <QRCode
                        value={`https://kohee-live.meshstream.io/viewer?name=${auth.token}&streamid=${streamId}`}
                      />
                      <a
                        href={`https://kohee-live.meshstream.io/viewer?name=${auth.token}&streamid=${streamId}`}
                        target="_blank"
                        rel="noreferrer"
                      >{`https://kohee-live.meshstream.io/viewer?name=${auth.token}&streamid=${streamId}`}</a>
                    </React.Fragment>
                  ) : null}
                </div>
              </Grid.Column>
              <Grid.Column>
                {isPublishing ? (
                  <div className={styles.signals}>
                    <div>
                      <h4>streamer:</h4>
                      <LatencySignal
                        currentRoundTripTime={
                          videoStatsOfStreamer.currentRoundTripTime
                        }
                      />
                    </div>
                    <div>
                      <h4>viewer:</h4>
                      <LatencySignal
                        currentRoundTripTime={
                          videoStatsOfViewer.currentRoundTripTime
                        }
                        jitterBufferDelayRate={
                          videoStatsOfViewer.jitterBufferDelayRate
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>
        {/* <section className={styles.bannerImages}>
          <Grid columns={3} stackable stretched>
            <Grid.Row>
              <Grid.Column>
                <img src={kohee1} alt="kohee" />
              </Grid.Column>
              <Grid.Column>
                <img src={kohee2} alt="kohee" />
              </Grid.Column>
              <Grid.Column>
                <img src={kohee3} alt="kohee" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section> */}
        {/* <section>
          <Contact />
        </section> */}
      </main>
      <Footer />
    </div>
  );
};

export default Streamer;
