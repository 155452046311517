import React from "react";
import { Button } from "semantic-ui-react";
import styles from "./index.module.scss";

const Banner = () => {
  return (
    <section className={styles.banner}>
      <div className={styles.content}>
        {/* <h1 className={styles.title}>可喜直播大廳</h1>
        <h2 className={styles.subTitle}>
          可喜直播讓您最放心，直播技術採用 MESHUB
        </h2> */}
        {/* <a
          className={styles.link}
          href="https://www.kohee.com.tw/"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            color="teal"
            style={{
              fontSize: "1.4rem",
              fontWeight: 500,
              letterSpacing: 2,
              marginTop: "8vh",
            }}
          >
            進入可喜空間
          </Button>
        </a> */}
      </div>
    </section>
  );
};

export default Banner;
