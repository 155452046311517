import backend from "../utils/backendAPI/sdkConfig";
import { attachMediaStreamToVideoElement } from "./attachMediaStreamToVideoElement";
const meshstreamClient = require("meshstream/client");
const { ConferenceApi } = meshstreamClient;

let playback = null;
let videoStats = {};

export const subscribe = async (streamId, videoRef, latlng, callback) => {
  // console.log("will subscribe");
  const sdkConfig = await backend.POST("/customer/config/sdk", {
    operation: 0,
    stream: streamId,
    ll_from: latlng,
  });

  playback = new ConferenceApi({
    kinds: ["audio", "video"],
    ...sdkConfig,
  });

  try {
    const mediaStream = await playback.subscribe();

    attachMediaStreamToVideoElement(playback, mediaStream, videoRef);
    playback.on("getstats", (result) => {
      if (result.kind === "video") {
        videoStats = { ...result };
        if (callback) {
          callback(result);
        }
      }

      if (result.kind === "audio") {
        // console.log("audio", JSON.stringify(result, null, 4));
      }
    });
    return videoStats;
  } catch (err) {
    alert(`Subscribe error:${err}`);
  }
};

export const unsubscribe = async (videoRef) => {
  await playback.unsubscribeTrack("video");
  await playback.unsubscribeTrack("audio");
  videoRef.current.srcObject = null;
};

export const getVideoStats = () => {
  return videoStats;
};
