import React, { useRef, useEffect } from "react";
import styles from "./index.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>&copy; 2021 by Meshub</div>
    </footer>
  );
};

export default Footer;
