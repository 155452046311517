import { SIGN_IN, SIGN_OUT, CHECK_AUTH } from "../actions/actionTypes";
import Cookies from "js-cookie";

const auth = JSON.parse(Cookies.get("auth") || "{}");

const initialState = {
  isAdmin: auth.isAdmin ? auth.isAdmin : false,
  token: auth.token,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SIGN_IN: {
      return { ...state, ...payload };
    }

    case SIGN_OUT: {
      return { ...state, isAdmin: false, token: "" };
    }

    case CHECK_AUTH: {
      return { ...state, ...payload };
    }

    case "SET_TOKEN": {
      return { ...state, token: payload };
    }

    default: {
      return state;
    }
  }
};
