import axios from "axios";
import history from "../../history";
import store from "../../store";

const baseURL = "https://meshub-health.meshstream.io/api";

const backend = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

backend.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      // history.push('/login');
    }
    return Promise.reject(error);
  }
);

export default {
  async POST(url, body, config) {
    try {
      const response = await backend.post(url, body, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
