export async function changeExistingLocalVideoSolution(
  width,
  height,
  videoElement
) {
  try {
    const videoTrack = videoElement.current.srcObject.getVideoTracks()[0];
    await videoTrack.applyConstraints({
      width: { ideal: width },
      height: { ideal: height },
    });

    const stream = new MediaStream();
    stream.addTrack(videoTrack);
    videoElement.current.srcObject = stream;
  } catch (err) {
    alert(`Resolution not supported`);
  }
}
