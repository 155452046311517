import React from "react";
import { connect } from "react-redux";
import history from "../../history";

import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { signIn } from "../../actions/auth";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { setSnackbar } from "../../actions/snackbar";

const md5 = require("md5");

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "50px",
    boxShadow: "0 0 6px rgb(216,216,216)",

    "& .MuiTextField-root": {
      margin: "8px 0",
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
    "& button": {
      display: "block",
      margin: "40px auto 0",
    },
    "& h1": {
      textAlign: "center",
    },
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Form = ({ signIn, setSnackbar }) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formikData = JSON.parse(JSON.stringify(values));

      try {
        await signIn({ ...formikData, password: md5(formikData.password) });
        formik.handleReset();
        setSnackbar({
          isSnackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "You Have Successfully Logged in",
        });
      } catch (error) {
        setSnackbar({
          isSnackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `This account doesn't exist`,
        });
      }
    },
  });

  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <h1>Login</h1>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
        onChange={formik.handleChange}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        fullWidth
        id="password"
        type="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <Button color="primary" variant="contained" type="submit">
        Login
      </Button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    options: state.options,
  };
};

export default connect(mapStateToProps, { signIn, setSnackbar })(Form);
