import backend from "../utils/backendAPI/admin";
import Cookies from "js-cookie";
import { SIGN_IN, SIGN_OUT, CHECK_AUTH } from "./actionTypes";

export const signIn = (data) => async (dispatch) => {
  try {
    const response = await backend.GET("/auth", data);
    dispatch({ type: SIGN_IN, payload: response });
    Cookies.set("auth", JSON.stringify(response), {
      secure: true,
      sameSite: "strict",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signOut = () => {
  Cookies.remove("auth");
  return { type: SIGN_OUT };
};

export const checkAuth = () => {
  const auth = JSON.parse(Cookies.get("auth") || "{}");
  return { type: CHECK_AUTH, payload: auth };
};

export const setToken = (token) => {
  return { type: "SET_TOKEN", payload: token };
};
