import React from "react";
import { useSelector, useDispatch } from "react-redux";
import history from "../../history";
import { signOut } from "../../actions/auth";
import { setSnackbar } from "../../actions/snackbar";
import styles from "./index.module.scss";

const AuthButton = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onClickHandler = () => {
    if (token) {
      dispatch(signOut());
      dispatch(
        setSnackbar({
          isSnackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Logged Out Successfully",
        })
      );
    }
  };

  return (
    <button
      className={`${styles.button} ${token ? styles.isAuth : null}`}
      onClick={onClickHandler}
    >
      {token ? "登出" : "登入"}
    </button>
  );
};

export default AuthButton;
